import React, { FC, useCallback, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { initialNewSection } from 'store/authenticate/authenticate.action';
import routes from '../routes/authenticate.route';
import Header from './Header';
import classes from './layout.module.scss';
import Footer from './Footer/Footer';

const layoutPrefix = '/auth';
const AuthLayout: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initialNewSection.request(''));
  }, [dispatch]);
  const renderRoute = useCallback(() => {
    return routes.map((route) => (
      <Route
        key={route.path}
        exact={route.exact}
        path={layoutPrefix + route.path}
        component={route.component}
      />
    ));
  }, []);

  const renderFooter = useCallback(() => {
    for (let i = 0; i < routes.length; i++) {
      if (
        location.pathname.includes(routes[i].path) &&
        routes[i].footer !== false
      ) {
        return <Footer key={routes[i].path} />;
      }
    }
  }, [location]);

  return (
    <div className={classes.wrapLayout}>
      <Header />
      <Switch>{renderRoute()}</Switch>
      {renderFooter()}
    </div>
  );
};

export default AuthLayout;
