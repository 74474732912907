import { call, put, takeLatest } from 'redux-saga/effects';
import { toastError } from 'helpers/utils.helper';
import {
  checkIsPabAdminAction,
  contactUsAction,
  trackSocialAction,
} from 'store/common/common.action';
import { getMessageFromError } from 'helpers/common.util';
import { checkPbaAdmin, contactUs, trackSocialClick } from 'api/common';

function* handleGetPbaInfo(
  action: ReturnType<typeof checkIsPabAdminAction.request>,
) {
  try {
    const response = yield call(checkPbaAdmin);
    yield put(checkIsPabAdminAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(checkIsPabAdminAction.failure(getMessageFromError(e)));
  }
}

function* handleContactUs(action: ReturnType<typeof contactUsAction.request>) {
  try {
    const response = yield call(contactUs, action.payload);
    yield put(contactUsAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(contactUsAction.failure(getMessageFromError(e)));
  }
}

function* handleTrackSocial(
  action: ReturnType<typeof trackSocialAction.request>,
) {
  try {
    const response = yield call(trackSocialClick, action.payload);
    yield put(trackSocialAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(trackSocialAction.failure(getMessageFromError(e)));
  }
}

export default function* commonSaga() {
  yield takeLatest(checkIsPabAdminAction.request, handleGetPbaInfo);
  yield takeLatest(contactUsAction.request, handleContactUs);
  yield takeLatest(trackSocialAction.request, handleTrackSocial);
}
