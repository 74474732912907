import { createReducer } from 'typesafe-actions';
import { AuthenticateStore } from 'models/authenticate';
import { doLogin, doLogout } from './authenticate.action';

const INITIAL_STATE: AuthenticateStore = {
  userInfo: null,
  loading: false,
  token: '',
  loggingIn: false,
  error: '',
};

const authenticateReducer = createReducer<AuthenticateStore>(INITIAL_STATE)
  .handleAction(doLogin.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(doLogin.success, (state, action) => ({
    ...state,
    loggingIn: true,
    token: action.payload.access_token,
    userInfo: action.payload.user,
    loading: false,
    error: '',
  }))
  .handleAction(doLogin.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(doLogout.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(doLogout.success, (state, action) => ({
    ...state,
    loggingIn: false,
    loading: false,
    userInfo: null,
    error: '',
  }))
  .handleAction(doLogout.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));

export default authenticateReducer;
