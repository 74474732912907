import { call, put, takeLatest, delay } from 'redux-saga/effects';

import { toastSuccess, toastError } from 'helpers/utils.helper';
import { ListNftsDefault } from 'models/nft';
import {
  getListCartAction,
  getNftsMarketplaceAction,
  addToCartAction,
  deleteFromCartAction,
} from 'store/marketplace/marketplace.action';
import { getMessageFromError } from 'helpers/common.util';
import {
  getListNfts,
  getListInCart,
  addToCart,
  deleteFromCart,
} from 'api/marketplace';
import { CartList, IMarketplaceItems } from 'models/marketplace';

function* handleGetListNfts(
  action: ReturnType<typeof getNftsMarketplaceAction.request>,
) {
  try {
    const response: IMarketplaceItems = yield call(getListNfts, action.payload);
    yield put(getNftsMarketplaceAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getNftsMarketplaceAction.failure(getMessageFromError(e)));
  }
}

function* handleGetListCart(
  action: ReturnType<typeof getListCartAction.request>,
) {
  try {
    const response: CartList = yield call(getListInCart);
    yield put(getListCartAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getListCartAction.failure(getMessageFromError(e)));
  }
}

function* handleAddToCart(action: ReturnType<typeof addToCartAction.request>) {
  try {
    const response: ListNftsDefault = yield call(addToCart, action.payload);
    toastSuccess('Add item to cart successfully.');
    yield put(getListCartAction.request(''));
    yield put(addToCartAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(addToCartAction.failure(getMessageFromError(e)));
  }
}

function* handleDeleteFromCart(
  action: ReturnType<typeof deleteFromCartAction.request>,
) {
  try {
    const response: ListNftsDefault = yield call(
      deleteFromCart,
      action.payload,
    );
    yield put(getListCartAction.request(''));
    yield delay(700);
    yield put(deleteFromCartAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(deleteFromCartAction.failure(getMessageFromError(e)));
  }
}

export default function* marketplaceSaga() {
  yield takeLatest(getNftsMarketplaceAction.request, handleGetListNfts);
  yield takeLatest(getListCartAction.request, handleGetListCart);
  yield takeLatest(addToCartAction.request, handleAddToCart);
  yield takeLatest(deleteFromCartAction.request, handleDeleteFromCart);
}
