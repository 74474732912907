import { createAsyncAction } from 'typesafe-actions';
import {
  GetListNfts,
  AddToCartReq,
  DeleteFromCartReq,
  CartList,
  IMarketplaceItems,
} from 'models/marketplace';
import { ListNftsDefault } from 'models/nft';

export const getNftsMarketplaceAction = createAsyncAction(
  'GET_NFTS_MARKETPLACE_REQUESTED',
  'GET_NFTS_MARKETPLACE_SUCCESS',
  'GET_NFTS_MARKETPLACE_FAILURE',
)<GetListNfts, IMarketplaceItems, string>();

export const addToCartAction = createAsyncAction(
  'ADD_TO_CART_REQUESTED',
  'ADD_TO_CART_SUCCESS',
  'ADD_TO_CART_FAILURE',
)<AddToCartReq, ListNftsDefault, string>();

export const deleteFromCartAction = createAsyncAction(
  'DELETE_CART_REQUESTED',
  'DELETE_CART_SUCCESS',
  'DELETE_CART_FAILURE',
)<DeleteFromCartReq, ListNftsDefault, string>();

export const getListCartAction = createAsyncAction(
  'GET_LIST_CART_REQUESTED',
  'GET_LIST_CART_SUCCESS',
  'GET_LIST_CART_FAILURE',
)<string, CartList, string>();
