import { createReducer } from 'typesafe-actions';
import {
  getNftsTrendingAction,
  checkIsPabAdminAction,
  contactUsAction,
  trackSocialAction,
} from './common.action';
import { CommonStore } from '../../models/common/common';

const INITIAL_STATE: CommonStore = {
  loading: false,
  error: '',
  listNftsTrending: null,
  isPba: null,
  isContactSent: false,
};

const commonReducer = createReducer<CommonStore>(INITIAL_STATE)
  .handleAction(getNftsTrendingAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getNftsTrendingAction.success, (state, action) => ({
    ...state,
    listNftsTrending: action.payload,
    loading: false,
    error: '',
  }))
  .handleAction(getNftsTrendingAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(checkIsPabAdminAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(checkIsPabAdminAction.success, (state, action) => ({
    ...state,
    isPba: action.payload?.data?.is_admin,
    loading: false,
    error: '',
  }))
  .handleAction(checkIsPabAdminAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(contactUsAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(contactUsAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: '',
    isContactSent: true,
  }))
  .handleAction(contactUsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    isContactSent: true, // temp
  }))
  .handleAction(trackSocialAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(trackSocialAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: '',
    isTrackSent: true,
  }))
  .handleAction(trackSocialAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    isTrackSent: true,
  }));

export default commonReducer;
