import { call, put, takeLatest } from 'redux-saga/effects';
import CookieBrowser from 'js-cookie';
import { UserInfoResponse } from 'models/authenticate';
import { getMessageFromError } from 'helpers/common.util';
import { toastSuccess, toastError } from 'helpers/utils.helper';
import { doLoginRequest } from 'api/authenticate';
import { tokenManager } from 'api/request/tokenManager';
import { getListCartAction } from 'store/marketplace/marketplace.action';
import { doLogin, initialNewSection, doLogout } from './authenticate.action';
import { TOKEN_KEY, USER_KEY, COOKIE_OPTION } from '../../constants/common';
import { history } from '../../helpers/history';

function* handleLogin(action: ReturnType<typeof doLogin.request>) {
  try {
    const response: UserInfoResponse = yield call(
      doLoginRequest,
      action.payload,
    );
    CookieBrowser.set(TOKEN_KEY, response?.access_token, COOKIE_OPTION);
    CookieBrowser.set(USER_KEY, response?.user, COOKIE_OPTION);
    tokenManager.setToken(response?.access_token);
    history.push('/account/profile');
    toastSuccess('Successful login.');
    yield put(doLogin.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(doLogin.failure(getMessageFromError(e)));
  }
}

function* checkLogin(action: ReturnType<typeof initialNewSection.request>) {
  try {
    const user = CookieBrowser.get(USER_KEY);
    const tokenKey = CookieBrowser.get(TOKEN_KEY);
    if (user && tokenKey) {
      const userInfo = JSON.parse(user);
      tokenManager.setToken(tokenKey);
      yield put(
        doLogin.success({
          access_token: tokenKey,
          user: userInfo,
        }),
      );
    } else {
      yield put(doLogin.failure(''));
    }
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(doLogin.failure(getMessageFromError(e)));
  }
}

function* handleLogout(action: ReturnType<typeof doLogout.request>) {
  try {
    // yield call(doLogoutRequest);
    CookieBrowser.remove(TOKEN_KEY);
    CookieBrowser.remove(USER_KEY);
    tokenManager.doLogout();
    toastSuccess('Logout successfully.');
    yield put(doLogout.success(''));
    yield put(getListCartAction.success(null));
    history.push('/');
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(doLogout.failure(getMessageFromError(e)));
  }
}

export default function* authenticateSaga() {
  yield takeLatest(doLogin.request, handleLogin);
  yield takeLatest(initialNewSection.request, checkLogin);
  yield takeLatest(doLogout.request, handleLogout);
}
