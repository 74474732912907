import React, { FC } from 'react';
import { useHistory } from 'react-router';

const NotFound: FC = () => {
  const history = useHistory();
  return (
    <div className="text-center pt-5">
      <h1>Not found page !</h1>
      <button
        type="button"
        className="mt-4"
        onClick={() => history.replace('/')}
      >
        Back Home
      </button>
    </div>
  );
};

export default NotFound;
