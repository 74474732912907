import { lazy } from 'react';

// const HomePage = lazy(() => import('container/home'));
// const GuidePage = lazy(() => import('container/theme-guide'));
const NotFound = lazy(() => import('container/404'));
// const NftCreationPage = lazy(
//   () => import('container/nft/nft-creation/NftCreation'),
// );
// const MarketplacePage = lazy(() => import('container/marketplace'));
// const CheckoutPage = lazy(() => import('container/checkout'));
const PowerUpPage = lazy(() => import('container/power-up'));
const FaqsPage = lazy(() => import('container/faqs'));
const ContactUsPage = lazy(() => import('container/contact-us'));
// const NftDetailPage = lazy(() => import('container/nft/detail'));
const AwardDetailPage = lazy(() => import('container/award/detail'));
const EditPurchasePage = lazy(() => import('container/nft/edit-purchase'));
const AccountProfilePage = lazy(() => import('container/account/profile'));
// const HistoryPage = lazy(() => import('container/account/history'));
const AccountSettingsPage = lazy(() => import('container/account/settings'));
const ImportKeyPage = lazy(() => import('container/import'));
const SplashPage = lazy(() => import('container/splash'));
const PublicProfilePage = lazy(
  () => import('container/account/public-profile'),
);
const LogoutPage = lazy(() => import('container/logout'));

const routes = [
  {
    path: '/',
    component: SplashPage,
    exact: true,
    footer: true,
  },
  {
    path: '/logout',
    component: LogoutPage,
    exact: true,
  },
  // {
  //   path: '/nft-marketplace',
  //   component: MarketplacePage,
  //   exact: true,
  // },
  // {
  //   path: '/checkout',
  //   component: CheckoutPage,
  //   exact: true,
  // },
  // {
  //   path: '/nft/:id',
  //   component: NftDetailPage,
  //   exact: true,
  // },
  {
    path: '/award/:id',
    component: AwardDetailPage,
    exact: true,
  },
  // {
  //   path: '/common-components',
  //   component: GuidePage,
  //   exact: true,
  // },
  // {
  //   path: '/user/nft-create',
  //   component: NftCreationPage,
  //   exact: true,
  // },
  {
    path: '/user/my-moments',
    component: PowerUpPage,
    exact: true,
  },
  {
    path: '/user/my-moments/:id',
    component: EditPurchasePage,
    exact: true,
  },
  {
    path: '/account/profile',
    component: AccountProfilePage,
    exact: true,
  },
  // {
  //   path: '/account/history/:type/:page?',
  //   component: HistoryPage,
  //   exact: true,
  // },
  {
    path: '/account/settings',
    component: AccountSettingsPage,
    exact: true,
  },
  {
    path: '/account/profile/:username',
    component: PublicProfilePage,
    exact: true,
  },
  {
    path: '/import',
    component: ImportKeyPage,
    exact: true,
  },
  {
    path: '/splash',
    component: SplashPage,
  },
  {
    path: '/faqs',
    component: FaqsPage,
    exact: true,
  },
  {
    path: '/contact-us',
    component: ContactUsPage,
    exact: true,
    footer: true,
  },
  {
    path: '*',
    component: NotFound,
    exact: true,
  },
];

export default routes;
