import { combineReducers, ReducersMapObject } from 'redux';
import { ActionType } from 'typesafe-actions';
import { StoreState } from '../models/store';
import commonReducer from './common/common.reducer';
import authenticateReducer from './authenticate/authenticate.reducer';
import nftReducer from './nft/nft.reducer';
import marketplaceReducer from './marketplace/marketplace.reducer';

const rootReducer: ReducersMapObject<
  StoreState,
  ActionType<typeof import('../store/action').default>
> = {
  common: commonReducer,
  authenticate: authenticateReducer,
  nft: nftReducer,
  marketplace: marketplaceReducer,
};

export default combineReducers(rootReducer);
