import { createReducer } from 'typesafe-actions';
import {
  getNftsTrendingAction,
  getNftsListedAction,
  createNftAction,
  getNftsPurchaseAction,
  getAwardNftsAction,
  getAwardNftsRandomAction,
  getNftUserBalanceAction,
  getNftsForSaleAction,
  getDetailNftAction,
  updateNftPurchaseAction,
  cancelNftSellingAction,
  getPriceHistory,
  getNftActivationStatus,
  getMyNfts,
  getAwardDetailsAction,
} from './nft.action';
import { NftStore } from '../../models/nft';

const INITIAL_STATE: NftStore = {
  loading: false,
  error: '',
  listNftsTrending: null,
  listNftsListed: null,
  listNftsPurchase: null,
  listAwardNfts: null,
  listAwardNftsRandom: null,
  listNftsForSale: null,
  listMyNfts: null,
  awardDetails: null,
  awardDetailsLoading: false,
  loadingMyNfts: false,
  userBalance: null,
  detailNft: null,
  otherListings: null,
  priceHistory: null,
  nftActivationLoading: false,
  nftActivationStatus: null,
};

const nftReducer = createReducer<NftStore>(INITIAL_STATE)
  .handleAction(getNftsTrendingAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getNftsTrendingAction.success, (state, action) => ({
    ...state,
    listNftsTrending: action.payload,
    loading: false,
    error: '',
  }))
  .handleAction(getNftsTrendingAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getNftsListedAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getNftsListedAction.success, (state, action) => ({
    ...state,
    listNftsListed: action.payload,
    loading: false,
    error: '',
  }))
  .handleAction(getNftsListedAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getNftsPurchaseAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getNftsPurchaseAction.success, (state, action) => ({
    ...state,
    listNftsPurchase: action.payload,
    loading: false,
    error: '',
  }))
  .handleAction(getNftsPurchaseAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getAwardNftsAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getAwardNftsAction.success, (state, action) => ({
    ...state,
    listAwardNfts: action.payload,
    loading: false,
    error: '',
  }))
  .handleAction(getAwardNftsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getAwardNftsRandomAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getAwardNftsRandomAction.success, (state, action) => ({
    ...state,
    listAwardNftsRandom: action.payload,
    loading: false,
    error: '',
  }))
  .handleAction(getAwardNftsRandomAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getAwardDetailsAction.request, (state, action) => {
    return {
      ...state,
      awardDetailsLoading: true,
    };
  })
  .handleAction(getAwardDetailsAction.success, (state, action) => ({
    ...state,
    awardDetails: action.payload,
    awardDetailsLoading: false,
    error: '',
  }))
  .handleAction(getAwardDetailsAction.failure, (state, action) => ({
    ...state,
    awardDetailsLoading: false,
    error: action.payload,
  }))
  .handleAction(createNftAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(createNftAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: '',
  }))
  .handleAction(createNftAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getNftUserBalanceAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getNftUserBalanceAction.success, (state, action) => ({
    ...state,
    loading: false,
    userBalance: action.payload,
    error: '',
  }))
  .handleAction(getNftUserBalanceAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getNftsForSaleAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getNftsForSaleAction.success, (state, action) => ({
    ...state,
    loading: false,
    listNftsForSale: action.payload,
    error: '',
  }))
  .handleAction(getNftsForSaleAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getDetailNftAction.request, (state, action) => {
    return {
      ...state,
      detailNft: null,
      loading: true,
    };
  })
  .handleAction(getDetailNftAction.success, (state, action) => ({
    ...state,
    loading: false,
    detailNft: action.payload[0]?.data,
    otherListings: action.payload[1]?.data,
    error: '',
  }))
  .handleAction(getDetailNftAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(updateNftPurchaseAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(updateNftPurchaseAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: '',
  }))
  .handleAction(updateNftPurchaseAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(cancelNftSellingAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(cancelNftSellingAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: '',
  }))
  .handleAction(cancelNftSellingAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getPriceHistory.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getPriceHistory.success, (state, action) => ({
    ...state,
    loading: false,
    priceHistory: action.payload,
    error: '',
  }))
  .handleAction(getPriceHistory.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getNftActivationStatus.request, (state, action) => {
    return {
      ...state,
      nftActivationLoading: true,
    };
  })
  .handleAction(getNftActivationStatus.success, (state, action) => ({
    ...state,
    nftActivationLoading: false,
    nftActivationStatus: action.payload,
    error: '',
  }))
  .handleAction(getNftActivationStatus.failure, (state, action) => ({
    ...state,
    nftActivationLoading: false,
    error: action.payload,
  }))
  .handleAction(getMyNfts.request, (state, action) => {
    return {
      ...state,
      loadingMyNfts: true,
    };
  })
  .handleAction(getMyNfts.success, (state, action) => ({
    ...state,
    loadingMyNfts: false,
    listMyNfts: action.payload,
    error: '',
  }))
  .handleAction(getMyNfts.failure, (state, action) => ({
    ...state,
    loadingMyNfts: false,
    error: action.payload,
  }));

export default nftReducer;
