import React, { FC } from 'react';
import cx from 'classnames';
import classes from './menu-icon.module.scss';

interface Props {
  active: boolean;
}
const MenuIcon: FC<Props> = ({ active }) => {
  return (
    <div className={cx(classes.wrap, { [classes.change]: active })}>
      <div className={classes.bar1} />
      <div className={classes.bar2} />
      <div className={classes.bar3} />
    </div>
  );
};
export default MenuIcon;
