import {
  LoginRequestModel,
  UserInfoResponse,
  // RegisterRequestModel,
} from 'models/authenticate';
import unauthorizedRequest from './request/unauthorizedRequest';
import authorizedRequest from './request/authorizedRequest';

export function doLoginRequest(params: LoginRequestModel) {
  return unauthorizedRequest.post<UserInfoResponse, UserInfoResponse>(
    'api/pba/login',
    params,
  );
}

export function doLogoutRequest() {
  return authorizedRequest.post<string, string>(`api/pba/logout`);
}

export function doRegisterRequest(params) {
  return unauthorizedRequest.post<string, string>(
    `api/v2/auth/register`,
    params,
  );
}

export function doVerifyEmailRequest(email, code) {
  return unauthorizedRequest.get<string, string>(
    `api/v2/auth/verify-email/${email}/${code}`,
  );
}

export function doResendVerificationRequest(email) {
  return unauthorizedRequest.post<string, string>(
    `api/v2/auth/resend-verify-email`,
    {
      email,
    },
  );
}
export function doForgottenPasswordEmailRequest(email) {
  return unauthorizedRequest.post<string, string>(
    `api/v2/auth/forgotten-password-email`,
    {
      email,
    },
  );
}
export function doForgottenPasswordResetRequest(passwords) {
  return unauthorizedRequest.post<string, string>(
    `api/v2/auth/forgotten-password-reset`,
    passwords,
  );
}
