export const tokenManager = {
  token: '',
  logout: [],
  setToken(t: string) {
    this.token = t;
  },
  setLogoutMethod(m: () => void) {
    this.logout = [m];
  },
  doLogout() {
    this.token = '';
  },
};
