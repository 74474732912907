import React, { useState, useMemo, useCallback } from 'react';
import { history } from 'helpers/history';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteFromCartAction,
  addToCartAction,
} from 'store/marketplace/marketplace.action';
import { StoreState } from 'models/store';
import { ICart } from 'models/marketplace';
import ImageButton from 'components/ui/button/image-button/ImageButton';
import InvisibleBackdrop from 'components/ui/backdrop/InvisibleBackdrop';
import Button from 'components/ui/button/default';
import ImgLoadable from 'components/ui/ImgLoadable';
import images from 'assets/img';
import classes from './cart.module.scss';

const Cart = () => {
  const { cart, loading, cartLoading } = useSelector(
    (state: StoreState) => state.marketplace,
  );
  const loggingIn = useSelector(
    (state: StoreState) => state.authenticate.loggingIn,
  );
  const dispatch = useDispatch();
  const [toggleList, setToggleList] = useState(false);
  const cartItems = useMemo(
    () => cart?.data?.reduce((current, item) => current + item.quantity, 0),
    [cart],
  );

  const totalPrice: number = useMemo(
    () =>
      cart?.data?.reduce(
        (total, current: ICart) => total + current.price * current.quantity,
        0,
      ),
    [cart],
  );

  const handleIncreaseItemQuantity = useCallback(
    (id: string) => {
      dispatch(addToCartAction.request({ id }));
    },
    [dispatch],
  );
  const handleDeleteItem = useCallback(
    (id: string, quantity: number) => {
      dispatch(
        deleteFromCartAction.request({
          id,
          number: quantity,
        }),
      );
    },
    [dispatch],
  );
  const handleCheckout = useCallback(() => {
    history.push('/checkout');
    setToggleList(false);
  }, []);

  const renderCart = useMemo(() => {
    // if (loading || cartLoading) {
    //   if (cart?.data?.length > 1) {
    //     return cart?.data?.map((item) => (
    //       <Skeleton height={132} width="100%" key={item?.id} />
    //     ));
    //   }
    //   return <Skeleton height={132} width="100%" />;
    // }
    if (cartItems === 0) {
      return (
        <h3 className={cx('text-center', classes.emptyCart)}>Cart empty.</h3>
      );
    }
    return cart?.data?.map((item) => (
      <div
        key={item.id}
        className={cx(
          classes.cartItem,
          ' d-flex justify-content-between align-items-center',
        )}
      >
        <ImgLoadable
          src={item?.attributes?.image || images.common.demoNft}
          alt="nft img"
          className={classes.nftImg}
        />
        <div className="info">
          <div className="font-weight-bold">{item?.name}</div>
          <div>{item?.price}$</div>
        </div>
        <div className={classes.actions}>
          <ImageButton
            className={classes.closeIcon}
            disabled={loading}
            onClick={() => handleDeleteItem(item?.id, 0)}
          >
            <img src={images.common.closeIcon} alt="closeIcon" />
          </ImageButton>
          <div className={cx(classes.subActions, 'd-flex align-items-center')}>
            <ImageButton
              className={classes.minus}
              onClick={() => handleDeleteItem(item?.id, 1)}
            >
              -
            </ImageButton>
            <div className={classes.quantity}>{item?.quantity}</div>
            <ImageButton
              className={classes.add}
              onClick={() => handleIncreaseItemQuantity(item?.id)}
            >
              +
            </ImageButton>
          </div>
        </div>
      </div>
    ));
  }, [loading, cartItems, cart, handleDeleteItem, handleIncreaseItemQuantity]);

  return cartItems > 0 ? (
    <div className={classes.wrap}>
      <Button
        buttonType="clear"
        onClick={() => setToggleList((prev) => !prev)}
        className={classes.wrapIcon}
      >
        <div className={classes.badgeIcon}>{cartItems}</div>
        <img src={images.common.cartIcon} alt="cartIcon" />
      </Button>
      <InvisibleBackdrop
        onClick={() => {
          if (window.innerWidth > 767) {
            setToggleList(false);
          }
        }}
      >
        <div
          className={cx(classes.cartList, {
            [classes.toggleList]: toggleList,
          })}
        >
          <div className={classes.list}>{renderCart}</div>
          <div className={classes.checkout}>
            <div className="font-weight-bold">Total: ${totalPrice}</div>
            <Button
              buttonSize="s"
              disabled={loading || !loggingIn || cartLoading}
              onClick={handleCheckout}
            >
              Proceed to Checkout
            </Button>
          </div>
        </div>
      </InvisibleBackdrop>
    </div>
  ) : null;
};

export default Cart;
