import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import images from 'assets/img';
import classes from './footer.module.scss';

const resourcesList = [
  {
    link: '/faqs',
    title: 'FAQS',
  },
  {
    link: '#',
    title: 'Privacy Policy',
  },
  {
    link: '/contact-us',
    title: 'Contact Us',
  },
  {
    link: '#',
    title: 'Terms & conditions',
  },
  {
    link: '#',
    title: 'The PBA Tour',
  },
];

const socialList = [
  {
    link: 'https://www.facebook.com/ProfessionalBowlersAssociation/',
    icon: 'facebook',
    name: 'Facebook',
  },
  {
    link: 'https://www.instagram.com/pbatour/?hl=en',
    icon: 'instagram',
    name: 'Instagram',
  },
  {
    link: 'https://twitter.com/PBATour',
    icon: 'twitter',
    name: 'Twitter',
  },
  {
    link: '#',
    icon: 'youtube',
    name: 'YouTube',
  },
  {
    link: '#',
    icon: 'flobowling',
    name: 'Flobowling',
  },
];

const Footer = () => {
  return (
    <div className={cx(classes.wrap)}>
      <div className={classes.container}>
        <div className="row">
          <div
            className={cx(classes.footerLogo, 'col-md-4 d-flex mb-5 mb-md-0')}
          >
            <Link to="/" className="d-block">
              <img src={images.pbaLogo} alt="Logo" className={classes.logo} />
            </Link>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-12 mb-5 mb-sm-0 col-sm-6 col-md-5">
                <h5 className={classes.footerTitle}>Resources</h5>
                <ul className={classes.footerMenu}>
                  {resourcesList.map((item) => (
                    <li key={item.title}>
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-12 col-sm-6 col-md-5">
                <h5 className={classes.footerTitle}>Stay connnected</h5>
                <ul className={classes.footerMenu}>
                  {socialList.map((item) => (
                    <li key={item.name} className={classes.footerSocialItem}>
                      <a
                        href={item.link}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                        className={classes.footerSocialLink}
                      >
                        <img
                          className={classes.socialIcon}
                          src={images.socialIcons[item.icon]}
                          alt={item.name}
                        />
                        <span>{item.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
