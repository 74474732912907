import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import NotFound from '../container/404';

const Login = lazy(() => import('../container/login'));
const SignUp = lazy(() => import('../container/signup'));
const VerifyEmail = lazy(() => import('../container/verify-email'));
const PasswordReset = lazy(() => import('../container/password-reset'));
const ChangeEmail = lazy(() => import('../container/change-email/index'));

const authenticateRoute = [
  {
    exact: true,
    path: '/auth',
    component() {
      return <Redirect to={'/auth/login'} />;
    },
    name: 'Auth',
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    footer: true,
  },
  {
    path: '/register',
    component: SignUp,
    exact: true,
  },
  {
    path: '/verify-email',
    component: VerifyEmail,
    exact: true,
  },
  {
    path: '/password-reset',
    component: PasswordReset,
    exact: true,
  },
  {
    path: '/change-email',
    component: ChangeEmail,
    exact: true,
  },
  {
    path: '*',
    component: NotFound,
    exact: true,
  },
];

export default authenticateRoute;
