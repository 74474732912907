// import productionConfig from './production.config';
import devConfig from './dev.config';

// const appStage = process.env.REACT_APP_STAGE
//   ? process.env.REACT_APP_STAGE.trim()
//   : 'production';

interface ConfigModel {
  BASE_URL: string;
  CHECKOUT_FRAMES_PUBLIC_KEY: string;
}

function Config(): ConfigModel {
  // if (appStage === 'production') {
  //   return productionConfig;
  // }
  return devConfig;
}

export default Config();
