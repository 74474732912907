import {
  ListNftsTrending,
  GetListNftTrending,
  NftCreationParams,
  NftUserBalance,
  IDetailNFT,
  DetailNftParams,
  UpdateNftPurchase,
  CancelNftSelling,
  PriceHistoryParams,
  NftActivationInfoRes,
  ListAwardNftsDefault,
  ListAwardNftsRandom,
  AwardDetails,
} from 'models/nft';
import { objectToFormData } from 'helpers/common.util';
import unauthorizedRequest from './request/unauthorizedRequest';
import authorizedRequest from './request/authorizedRequest';

export function getListTrendingNfts(params: GetListNftTrending) {
  return unauthorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    'api/nft/trending-list',
  );
}

export function getListRecentListed(params: GetListNftTrending) {
  return unauthorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    'api/nft/recently-minted-list',
  );
}

export function createNfts(params: NftCreationParams) {
  const bodyParams = objectToFormData(params);
  return authorizedRequest.post<string, string>('api/nft/creation', bodyParams);
}

export function getListPurchaseNfts() {
  return authorizedRequest.get<NftUserBalance, NftUserBalance>(
    'api/nft/user-purchases',
  );
}

export function getNftUserBalance() {
  return authorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    'api/nft/user-pending-balance',
  );
}

export function getListForSaleNfts() {
  return authorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    'api/nft/user-exchanges',
  );
}

export function getDetailNft({ id, ...params }: DetailNftParams) {
  return Promise.all([
    unauthorizedRequest.get<IDetailNFT, IDetailNFT>(`api/nft/moment/${id}`, {
      params,
    }),
    unauthorizedRequest.get(
      `api/nft/moment-marketplace-list?moment_id=${params?.moment_id}`,
    ),
  ]);
}

export function updateNftPurchase(params: UpdateNftPurchase) {
  // const bodyParams = objectToFormData(params);
  return authorizedRequest.post<string, string>(
    'api/nft/set-moment-for-exchange',
    params,
  );
}

export function cancelNftSelling(payload: CancelNftSelling) {
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const { onSuccess, ...params } = payload;
  return authorizedRequest.post<string, string>(
    'api/nft/remove-moment-for-exchange',
    params,
  );
}
export function getPriceHistory(params: PriceHistoryParams) {
  return authorizedRequest.get<string, string>('api/nft/moment-price-history', {
    params,
  });
}

export function getPublicUserNfts(username: string) {
  return unauthorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    `api/pba/get-user-nfts/${username}`,
  );
}

export function getPublicSoldUserNfts(username: string) {
  return unauthorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    `api/pba/get-user-sold-nfts/${username}`,
  );
}

export function activePackItemRequest(id: string) {
  return authorizedRequest.post<string, string>(`api/nft/activate-moment`, {
    id,
  });
}

export function getNftActivationStatusRequest(id: string) {
  return authorizedRequest.get<NftActivationInfoRes, NftActivationInfoRes>(
    `api/nft/get-activation-status/${id}`,
  );
}
export function redeemNftRequest(id: string) {
  return authorizedRequest.post<NftActivationInfoRes, NftActivationInfoRes>(
    `api/nft/activate-moment`,
    { id },
  );
}

export function doImportNFTRequest(params) {
  const { loggedIn } = params;
  if (loggedIn) {
    return authorizedRequest.post<string, any>(
      'api/leaguepals-award/redeem',
      params,
    );
  }
  return unauthorizedRequest.post<string, any>(
    'api/leaguepals-award/redeem',
    params,
  );
}

export function getUserAwardsNFT({
  sort_type = 'date',
  order = 'desc',
  page = 1,
}) {
  return authorizedRequest.get<ListAwardNftsDefault, ListAwardNftsDefault>(
    `api/leaguepals-award/list?sort_type=${sort_type}&order=${order}&page=${page}`,
  );
}

export function getAwardsNFTRandom({ limit = 7 }) {
  return unauthorizedRequest.get<ListAwardNftsRandom, ListAwardNftsRandom>(
    `api/leaguepals-award/list-random?limit=${limit}`,
  );
}

export function getAwardDetails({ id }) {
  return unauthorizedRequest.get<AwardDetails, AwardDetails>(
    `api/leaguepals-award/award/${id}`,
  );
}
