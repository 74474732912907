import React, { FC } from 'react';
import './loading.scss';

interface Props {}

const CircleLoading: FC<Props> = () => {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default CircleLoading;
