import {
  GetListNfts,
  AddToCartReq,
  DeleteFromCartReq,
  ICheckout,
  IMarketplaceItems,
} from 'models/marketplace';
import { ListNftsDefault } from 'models/nft';
import { ISearchOptions } from 'models/common/common';
import unauthorizedRequest from './request/unauthorizedRequest';
import authorizedRequest from './request/authorizedRequest';

export function getListNfts(params: GetListNfts) {
  return unauthorizedRequest.get<IMarketplaceItems, IMarketplaceItems>(
    'api/nft/marketplace-list',
    { params },
  );
}

export function addToCart(params: AddToCartReq) {
  return authorizedRequest.post<ListNftsDefault, ListNftsDefault>(
    'api/marketplace/add-cart-item',
    params,
  );
}
export function deleteFromCart(params: DeleteFromCartReq) {
  return authorizedRequest.post<ListNftsDefault, ListNftsDefault>(
    'api/marketplace/remove-cart-item',
    params,
  );
}
export function getListInCart() {
  return authorizedRequest.get<ListNftsDefault, ListNftsDefault>(
    'api/marketplace/check-cart',
  );
}

export function checkoutNft() {
  return authorizedRequest.post<ICheckout, ICheckout>(
    'api/marketplace/checkout',
  );
}

export function checkoutPayment(token: string) {
  return authorizedRequest.post('api/payment/checkout', { token });
}

export function getListTagsOptions() {
  return unauthorizedRequest.get<ISearchOptions, ISearchOptions>(
    'api/nft/marketplace-search-tags',
  );
}
