import { call, put, takeLatest } from 'redux-saga/effects';
import { ListNftsTrending, NftUserBalance, IDetailNFT } from 'models/nft';
import { toastSuccess, toastError } from 'helpers/utils.helper';
import {
  getNftsTrendingAction,
  getNftsListedAction,
  createNftAction,
  getNftsPurchaseAction,
  getNftUserBalanceAction,
  getNftsForSaleAction,
  getDetailNftAction,
  updateNftPurchaseAction,
  cancelNftSellingAction,
  getNftActivationStatus,
  getMyNfts,
  getAwardNftsAction,
  getAwardNftsRandomAction,
  getAwardDetailsAction,
} from 'store/nft/nft.action';
import { getMessageFromError } from 'helpers/common.util';
import { history } from 'helpers/history';
import { getMyNftsRequest } from 'api/user';
import {
  getListTrendingNfts,
  getListRecentListed,
  createNfts,
  getListPurchaseNfts,
  getNftUserBalance,
  getListForSaleNfts,
  getDetailNft,
  updateNftPurchase,
  cancelNftSelling,
  getNftActivationStatusRequest,
  getUserAwardsNFT,
  getAwardsNFTRandom,
  getAwardDetails,
} from 'api/nft';

function* handleGetTrendingNft(
  action: ReturnType<typeof getNftsTrendingAction.request>,
) {
  try {
    const response: ListNftsTrending = yield call(
      getListTrendingNfts,
      action.payload,
    );
    yield put(getNftsTrendingAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getNftsTrendingAction.failure(getMessageFromError(e)));
  }
}

function* handleGetListedNft(
  action: ReturnType<typeof getNftsListedAction.request>,
) {
  try {
    const response: ListNftsTrending = yield call(
      getListRecentListed,
      action.payload,
    );
    yield put(getNftsListedAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getNftsListedAction.failure(getMessageFromError(e)));
  }
}

function* handleCreateNft(action: ReturnType<typeof createNftAction.request>) {
  try {
    yield call(createNfts, action?.payload?.params);
    toastSuccess('Create nft successfully.');
    history.replace('/nft-marketplace');
    yield put(createNftAction.success(''));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(createNftAction.failure(getMessageFromError(e)));
  }
}

function* handleGetPurchaseNft(
  action: ReturnType<typeof getNftsPurchaseAction.request>,
) {
  try {
    const response: any = yield call(getListPurchaseNfts);
    yield put(getNftsPurchaseAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getNftsPurchaseAction.failure(getMessageFromError(e)));
  }
}

function* handleGetAwardNft(
  action: ReturnType<typeof getAwardNftsAction.request>,
) {
  try {
    const response: any = yield call(getUserAwardsNFT, {
      sort_type: action.payload.sort_type,
      order: action.payload.order,
      page: action.payload.page,
    });
    // const transformedResponse =
    //   response?.data[0]?.constructor === Array
    //     ? {
    //         ...response,
    //         data: response?.data,
    //       }
    //     : response;
    // console.log(transformedResponse, 'transformedResponse');
    yield put(getAwardNftsAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getAwardNftsAction.failure(getMessageFromError(e)));
  }
}

function* handleGetAwardNftRandom(
  action: ReturnType<typeof getAwardNftsRandomAction.request>,
) {
  try {
    const response: any = yield call(getAwardsNFTRandom, {
      limit: Number(action.payload.limit) || 7,
    });
    const transformedResponse =
      response?.data[0]?.constructor === Array
        ? {
            ...response,
            data: response?.data[0],
          }
        : response;
    yield put(getAwardNftsRandomAction.success(transformedResponse));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getAwardNftsRandomAction.failure(getMessageFromError(e)));
  }
}

function* handleGetAwardDetails(
  action: ReturnType<typeof getAwardDetailsAction.request>,
) {
  try {
    const response: any = yield call(getAwardDetails, {
      id: action.payload.id,
    });

    yield put(getAwardDetailsAction.success(response?.data?.award || response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getAwardDetailsAction.failure(getMessageFromError(e)));
  }
}

function* handleGetNftUserBalance(
  action: ReturnType<typeof getNftUserBalanceAction.request>,
) {
  try {
    const response: NftUserBalance = yield call(getNftUserBalance);
    yield put(getNftUserBalanceAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getNftUserBalanceAction.failure(getMessageFromError(e)));
  }
}

function* handleGetNftForSale(
  action: ReturnType<typeof getNftsForSaleAction.request>,
) {
  try {
    const response: ListNftsTrending = yield call(getListForSaleNfts);
    yield put(getNftsForSaleAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getNftsForSaleAction.failure(getMessageFromError(e)));
  }
}

function* handleDetailNft(
  action: ReturnType<typeof getDetailNftAction.request>,
) {
  try {
    const response: IDetailNFT = yield call(getDetailNft, action.payload);
    yield put(getDetailNftAction.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getDetailNftAction.failure(getMessageFromError(e)));
  }
}

function* handleUpdateNft(
  action: ReturnType<typeof updateNftPurchaseAction.request>,
) {
  try {
    const response = yield call(updateNftPurchase, action.payload);
    yield put(updateNftPurchaseAction.success(response));
    toastSuccess('Update nft purchase successfully.');
    history.push('/user/my-moments');
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(updateNftPurchaseAction.failure(getMessageFromError(e)));
  }
}
function* handleCancelNftSelling(
  action: ReturnType<typeof cancelNftSellingAction.request>,
) {
  try {
    const response = yield call(cancelNftSelling, action.payload);
    yield put(cancelNftSellingAction.success(response));
    yield put(getNftsForSaleAction.request(''));
    toastSuccess('Cancel nft successfully.');
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(cancelNftSellingAction.failure(getMessageFromError(e)));
  }
}

function* handleNftActivationStatus(
  action: ReturnType<typeof getNftActivationStatus.request>,
) {
  try {
    const response = yield call(getNftActivationStatusRequest, action.payload);
    yield put(getNftActivationStatus.success(response?.data));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getNftActivationStatus.failure(getMessageFromError(e)));
  }
}
function* handleGetMyNfts(action: ReturnType<typeof getMyNfts.request>) {
  try {
    const response = yield call(getMyNftsRequest);
    yield put(getMyNfts.success(response));
  } catch (e) {
    toastError(getMessageFromError(e));
    yield put(getMyNfts.failure(getMessageFromError(e)));
  }
}

export default function* nftSaga() {
  yield takeLatest(getNftsTrendingAction.request, handleGetTrendingNft);
  yield takeLatest(getNftsListedAction.request, handleGetListedNft);
  yield takeLatest(createNftAction.request, handleCreateNft);
  yield takeLatest(getNftsPurchaseAction.request, handleGetPurchaseNft);
  yield takeLatest(getAwardNftsAction.request, handleGetAwardNft);
  yield takeLatest(getAwardNftsRandomAction.request, handleGetAwardNftRandom);
  yield takeLatest(getAwardDetailsAction.request, handleGetAwardDetails);
  yield takeLatest(getNftUserBalanceAction.request, handleGetNftUserBalance);
  yield takeLatest(getNftsForSaleAction.request, handleGetNftForSale);
  yield takeLatest(getDetailNftAction.request, handleDetailNft);
  yield takeLatest(updateNftPurchaseAction.request, handleUpdateNft);
  yield takeLatest(cancelNftSellingAction.request, handleCancelNftSelling);
  yield takeLatest(getNftActivationStatus.request, handleNftActivationStatus);
  yield takeLatest(getMyNfts.request, handleGetMyNfts);
}
