import { ListNftsTrending } from 'models/nft';
import CookieBrowser from 'js-cookie';
import { objectToFormData } from 'helpers/common.util';
import { TOKEN_KEY, USER_KEY } from 'constants/common';
import unauthorizedRequest from './request/unauthorizedRequest';
import authorizedRequest from './request/authorizedRequest';

export function getMyNftsRequest() {
  return authorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    'api/user/get-my-nfts',
  );
}

export function getMyProfile({ secret_key = '' } = {}) {
  const user = CookieBrowser.get(USER_KEY);
  const tokenKey = CookieBrowser.get(TOKEN_KEY);
  if (secret_key && !user && !tokenKey) {
    return unauthorizedRequest.get<any, any>('api/user/get-my-profile');
  }
  return authorizedRequest.get<any, any>('api/user/get-my-profile');
}

export function changeMyEmail(email, confirmation_code) {
  return authorizedRequest.put<any, any>('api/v2/auth/change-email', {
    email,
    confirmation_code,
  });
}

export function requestChangeMyEmail(email) {
  return authorizedRequest.post<any, any>('api/v2/auth/request-change-email', {
    email,
  });
}

export function changeMyUsername(username) {
  return authorizedRequest.put<any, any>('api/v2/profile/change-username', {
    username,
  });
}

export function deleteMyAccount() {
  return authorizedRequest.delete<any, any>('api/v2/auth/delete-account');
}

export function getSettings() {
  return authorizedRequest.get<any, any>('api/pba/user-settings');
}

export function getMySoldNfts() {
  return authorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    `api/user/get-my-sold-nfts`,
  );
}

export function getPublicProfile(username: string) {
  return unauthorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    `api/pba/get-user-profile/${username}`,
  );
}

export function getSalesHistory(page: string) {
  return authorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    `api/v2/history/get-sales-orders`,
  );
}
export function getPurchasedHistory(page: string) {
  return authorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    `api/v2/history/get-purchased-orders`,
  );
}

export function changeUserAvatar(avatar: File) {
  const params = objectToFormData({ avatar });
  return authorizedRequest.post<string, string>(
    `api/user/upload-avatar`,
    params,
  );
}
export function changeUserNameRequest(username: string) {
  return authorizedRequest.put<string, string>(`api/user/change-username`, {
    username,
  });
}
