import { createReducer } from 'typesafe-actions';
import {
  getNftsMarketplaceAction,
  getListCartAction,
  addToCartAction,
  deleteFromCartAction,
} from './marketplace.action';
import { MarketplaceStore } from '../../models/marketplace';

const INITIAL_STATE: MarketplaceStore = {
  loading: false,
  cartLoading: false,
  error: '',
  products: null,
  cart: null,
};

const marketplaceReducer = createReducer<MarketplaceStore>(INITIAL_STATE)
  .handleAction(getNftsMarketplaceAction.request, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(getNftsMarketplaceAction.success, (state, action) => ({
    ...state,
    products: action.payload,
    loading: false,
    error: '',
  }))
  .handleAction(getNftsMarketplaceAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getListCartAction.request, (state, action) => {
    return {
      ...state,
      cartLoading: true,
    };
  })
  .handleAction(getListCartAction.success, (state, action) => ({
    ...state,
    cart: action.payload,
    cartLoading: false,
    error: '',
  }))
  .handleAction(getListCartAction.failure, (state, action) => ({
    ...state,
    cartLoading: false,
    error: action.payload,
  }))
  .handleAction(addToCartAction.request, (state, action) => {
    return {
      ...state,
      cartLoading: true,
    };
  })
  .handleAction(addToCartAction.success, (state, action) => ({
    ...state,
    cartLoading: false,
    error: '',
  }))
  .handleAction(addToCartAction.failure, (state, action) => ({
    ...state,
    cartLoading: false,
    error: action.payload,
  }))
  .handleAction(deleteFromCartAction.request, (state, action) => {
    return {
      ...state,
      cartLoading: true,
    };
  })
  .handleAction(deleteFromCartAction.success, (state, action) => ({
    ...state,
    cartLoading: false,
    error: '',
  }))
  .handleAction(deleteFromCartAction.failure, (state, action) => ({
    ...state,
    cartLoading: false,
    error: action.payload,
  }));

export default marketplaceReducer;
