import React, { FC, useEffect, useCallback } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { checkIsPabAdminAction } from 'store/common/common.action';
import { StoreState } from 'models/store';
import { useDispatch, useSelector } from 'react-redux';
import { initialNewSection } from 'store/authenticate/authenticate.action';
import { getListCartAction } from 'store/marketplace/marketplace.action';
import routes from '../routes/app.route';
import classes from './layout.module.scss';
import Header from './Header';
import Footer from './Footer/Footer';

const MainLayout: FC = () => {
  const location = useLocation();
  const loggingIn = useSelector(
    (state: StoreState) => state.authenticate.loggingIn,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initialNewSection.request(''));
  }, [dispatch]);
  useEffect(() => {
    if (loggingIn) {
      dispatch(getListCartAction.request(''));
      dispatch(checkIsPabAdminAction.request(''));
    }
  }, [loggingIn, dispatch]);
  const renderRoute = useCallback(() => {
    return routes.map((i) => (
      <Route
        key={i.path}
        exact={i.exact}
        path={i.path}
        component={i.component}
      />
    ));
  }, []);

  const renderFooter = useCallback(() => {
    return routes.map((i) => {
      if (i.path === location.pathname && i.footer !== false) {
        return <Footer key={i.path} />;
      }
      return false;
    });
  }, [location]);

  return (
    <div className={classes.wrapLayout}>
      <Header />
      <div className={classes.wrapBody}>
        <Switch>{renderRoute()}</Switch>
      </div>
      {renderFooter()}
    </div>
  );
};

export default MainLayout;
