import { useCallback, useEffect, useState } from 'react';

export function useScrollTracking(maxTopTracking: number = 99999999) {
  const [top, setTop] = useState(0);

  const getTop = useCallback(() => {
    return Math.min(maxTopTracking, document.body.scrollTop);
  }, [maxTopTracking]);
  useEffect(() => {
    setTop(getTop());
    const onScroll = () => {
      if (document.body.scrollTop < maxTopTracking) {
        setTop(getTop());
      }
      if (document.body.scrollTop >= maxTopTracking && top !== maxTopTracking) {
        setTop(maxTopTracking);
      }
    };
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  }, [getTop, top, maxTopTracking]);
  return top;
}
