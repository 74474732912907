import { fork, all } from '@redux-saga/core/effects';
import commonSaga from './common/common.saga';
import authenticateSaga from './authenticate/authenticate.saga';
import nftSaga from './nft/nft.saga';
import marketplaceSaga from './marketplace/marketplace.saga';

export default function* rootSaga() {
  try {
    yield all([
      fork(commonSaga),
      fork(authenticateSaga),
      fork(nftSaga),
      fork(marketplaceSaga),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
