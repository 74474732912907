import React, {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
} from 'react';
import CircleLoading from 'components/ui/custom-loading/CircleLoading';
import cx from 'classnames';
import './button.scss';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Third = 'third',
  Danger = 'danger',
  Warning = 'warning',
  Outline = 'outline',
  Transparent = 'transparent',
  Clear = 'clear',
}

export enum ButtonSize {
  Large = 'l',
  Medium = 'm',
  Small = 's',
  ExtraLarge = 'xl',
}

interface Props extends React.ButtonHTMLAttributes<any> {
  buttonType?:
    | ButtonType
    | 'primary'
    | 'secondary'
    | 'third'
    | 'danger'
    | 'warning'
    | 'outline'
    | 'transparent'
    | 'clear';
  buttonSize?: 'l' | 'm' | 's' | 'xl';
  size?: 'l' | 'm' | 's' | 'xl';
  innerRef?: MutableRefObject<HTMLButtonElement>;
  loading?: boolean;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  props,
  ref,
) => {
  const {
    buttonType = 'primary',
    size,
    buttonSize = size || 'm',
    children,
    className,
    innerRef,
    loading,
    ...other
  } = props;

  return (
    <button
      type={'button'}
      ref={innerRef || ref}
      className={cx(
        'button',
        {
          primary: buttonType === ButtonType.Primary,
          secondary: buttonType === ButtonType.Secondary,
          third: buttonType === ButtonType.Third,
          danger: buttonType === ButtonType.Danger,
          warning: buttonType === ButtonType.Warning,
          outline: buttonType === ButtonType.Outline,
          transparent: buttonType === ButtonType.Transparent,
          clear: buttonType === ButtonType.Clear,
          medium: buttonSize === ButtonSize.Medium,
          large: buttonSize === ButtonSize.Large,
          small: buttonSize === ButtonSize.Small,
        },
        className,
      )}
      {...other}
    >
      {loading ? <CircleLoading /> : children}
    </button>
  );
};
export default forwardRef<HTMLButtonElement, Props>(Button);
