import { createAsyncAction } from 'typesafe-actions';
import {
  GetListNftTrending,
  ListNftsTrending,
  NftCreationRequest,
  NftUserBalance,
  DetailNftParams,
  IDetailNFT,
  UpdateNftPurchase,
  CancelNftSelling,
  PriceHistoryParams,
  NftActivationInfo,
  ListAwardNftsDefault,
  ListAwardNftsRandom,
  AwardDetails,
} from 'models/nft';

export const getNftsTrendingAction = createAsyncAction(
  'GET_NFTS_TRENDING_REQUESTED',
  'GET_NFTS_TRENDING_SUCCESS',
  'GET_NFTS_TRENDING_FAILURE',
)<GetListNftTrending, ListNftsTrending, string>();

export const getNftsListedAction = createAsyncAction(
  'GET_NFTS_LISTED_REQUESTED',
  'GET_NFTS_LISTED_SUCCESS',
  'GET_NFTS_LISTED_FAILURE',
)<GetListNftTrending, ListNftsTrending, string>();

export const createNftAction = createAsyncAction(
  'CREATE_NFT_REQUESTED',
  'CREATE_NFT_SUCCESS',
  'CREATE_NFT_FAILURE',
)<NftCreationRequest, string, string>();

export const getNftsPurchaseAction = createAsyncAction(
  'GET_NFTS_PURCHASE_REQUESTED',
  'GET_NFTS_PURCHASE_SUCCESS',
  'GET_NFTS_PURCHASE_FAILURE',
)<GetListNftTrending, ListNftsTrending, string>();

export const getAwardNftsAction = createAsyncAction(
  'GET_AWARD_NFTS_REQUESTED',
  'GET_AWARD_NFTS_SUCCESS',
  'GET_AWARD_NFTS_FAILURE',
)<GetListNftTrending, ListAwardNftsDefault, string>();

export const getAwardNftsRandomAction = createAsyncAction(
  'GET_AWARD_NFTS_RANDOM_REQUESTED',
  'GET_AWARD_NFTS_RANDOM_SUCCESS',
  'GET_AWARD_NFTS_RANDOM_FAILURE',
)<{ limit: number }, ListAwardNftsRandom, string>();

export const getAwardDetailsAction = createAsyncAction(
  'GET_AWARD_DETAILS_REQUESTED',
  'GET_AWARD_DETAILS_SUCCESS',
  'GET_AWARD_DETAILS_FAILURE',
)<{ id: string }, AwardDetails, string>();

export const getNftUserBalanceAction = createAsyncAction(
  'GET_NFTS_NFT_USER_BALANCE_REQUESTED',
  'GET_NFTS_NFT_USER_BALANCE_SUCCESS',
  'GET_NFTS_NFT_USER_BALANCE_FAILURE',
)<string, NftUserBalance, string>();

export const getNftsForSaleAction = createAsyncAction(
  'GET_NFTS_FOR_SALE_REQUESTED',
  'GET_NFTS_FOR_SALE_SUCCESS',
  'GET_NFTS_FOR_SALE_FAILURE',
)<string, ListNftsTrending, string>();

export const getDetailNftAction = createAsyncAction(
  'GET_DETAIL_NFT_REQUESTED',
  'GET_DETAIL_NFT_SUCCESS',
  'GET_DETAIL_NFT_FAILURE',
)<DetailNftParams, IDetailNFT, string>();

export const updateNftPurchaseAction = createAsyncAction(
  'NFT_PURCHASE_REQUESTED',
  'NFT_PURCHASE_SUCCESS',
  'NFT_PURCHASE_FAILURE',
)<UpdateNftPurchase, string, string>();

export const cancelNftSellingAction = createAsyncAction(
  'DELETE_NFT_EXCHANGE_REQUESTED',
  'DELETE_NFT_EXCHANGE_SUCCESS',
  'DELETE_NFT_EXCHANGE_FAILURE',
)<CancelNftSelling, string, string>();

export const getPriceHistory = createAsyncAction(
  'GET_PRICE_HISTORY_REQUESTED',
  'GET_PRICE_HISTORY_SUCCESS',
  'GET_PRICE_HISTORY_FAILURE',
)<PriceHistoryParams, string, string>();

export const getNftActivationStatus = createAsyncAction(
  'GET_NFT_ACTIVATION_STATUS_REQUESTED',
  'GET_NFT_ACTIVATION_STATUS_SUCCESS',
  'GET_NFT_ACTIVATION_STATUS_FAILURE',
)<string, NftActivationInfo, string>();

export const getMyNfts = createAsyncAction(
  'GET_MY_NFTS_REQUESTED',
  'GET_MY_NFTS_SUCCESS',
  'GET_MY_NFTS_FAILURE',
)<GetListNftTrending, ListNftsTrending, string>();
