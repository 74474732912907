import { createAsyncAction } from 'typesafe-actions';
import {
  ContactForm,
  GetListNftTrending,
  IPbaCheck,
} from 'models/common/common';
import { ListNftsDefault } from 'models/nft';

export const getNftsTrendingAction = createAsyncAction(
  'GET_NFTS_TRENDING_REQUESTED',
  'GET_NFTS_TRENDING_SUCCESS',
  'GET_NFTS_TRENDING_FAILURE',
)<GetListNftTrending, ListNftsDefault, string>();

export const checkIsPabAdminAction = createAsyncAction(
  'CHECK_IS_PBA_REQUESTED',
  'CHECK_IS_PBA_SUCCESS',
  'CHECK_IS_PBA_FAILURE',
)<string, IPbaCheck, string>();

export const contactUsAction = createAsyncAction(
  'CONTACT_US_REQUESTED',
  'CONTACT_US_SUCCESS',
  'CONTACT_US_FAILURE',
)<ContactForm, any, string>();

export const trackSocialAction = createAsyncAction(
  'TRACK_SOCIAL_REQUESTED',
  'TRACK_SOCIAL_SUCCESS',
  'TRACK_SOCIAL_FAILURE',
)<any, any, string>();
