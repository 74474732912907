import { AxiosError } from 'axios';
import { isNil, trim } from 'lodash';
import numeral from 'numeral';
import { toastSuccess } from 'helpers/utils.helper';
import { COMMON } from '../constants/error';

type E = string | AxiosError;

export function getMessageFromError(error: E) {
  if (typeof error === 'string') {
    return error;
  }
  if (
    typeof error?.response?.data?.error === 'string' &&
    error?.response?.data?.error === 'Unauthenticated.'
  ) {
    return 'User is not logged in';
  }
  if (typeof error?.response?.data?.error === 'string') {
    return error?.response?.data?.error;
  }
  if (error?.response) {
    return error?.response?.data?.message || COMMON;
  }
  return error?.message || COMMON;
}

export function shallowTrim<T>(values: T): T {
  return Object.keys(values).reduce((acc, key) => {
    return { ...acc, [key]: trim(values[key] || '') };
  }, {}) as T;
}
export function deepTrim<T>(values: T): T {
  return Object.keys(values).reduce((acc, key) => {
    if (typeof values[key] === 'object') {
      return { ...acc, [key]: deepTrim(values[key]) };
    }
    return { ...acc, [key]: trim(values[key]) };
  }, {}) as T;
}

export function decodeToken(token: string) {
  try {
    const payload = token.split('.')[1];
    const parsed = atob(payload);
    return JSON.parse(parsed);
  } catch (e) {
    return null;
  }
}

export function formatCurrency(s: number | string): string {
  if (isNil(s)) {
    return '';
  }
  return numeral(s).format('$0,0');
}

export function objectToFormData(object: Object) {
  const bodyParams = new FormData();
  for (const property in object) {
    if (property !== undefined && property !== null) {
      bodyParams.append(property, object[property]);
    }
  }
  return bodyParams;
}

export function getFullImageLink(link: string) {
  if (!link) {
    return null;
  }
  return `https://pipeflare-dev.s3.us-east-2.amazonaws.com/assets/avatar/${link}`;
}

export function handleCopyText(text: string) {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  toastSuccess('Copy text successfully.');
}

export const populateLink = (link: string) => {
  if (!link || link === '#') {
    return '#';
  }
  if (link?.includes('http')) {
    return link;
  }
  return `https://${link}`;
};

export function getMediaType(source: string) {
  // const images = ['jpg', 'gif', 'png']
  const videos = ['mp4', '3gp', 'ogg'];

  const url = new URL(source);
  const extension = url.pathname.split('.')[1];

  return videos.includes(extension) ? 'video' : 'image';
}
