import React, { useState, useCallback } from 'react';
import images from 'assets/img/index';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollDirection } from 'hooks/useScrollDirection';
import { useScrollTracking } from 'hooks/useScrollTracking';
import Button from 'components/ui/button/default';
import ImageButton from 'components/ui/button/image-button/ImageButton';
import MenuIcon from 'components/ui/menu-icon/index';
import cx from 'classnames';
import { doLogout } from 'store/authenticate/authenticate.action';
import Cart from 'components/marketplace/Cart';
import { StoreState } from 'models/store';
import classes from './layout.module.scss';

const { pbaLogo } = images;
const Menus = [
  // {
  //   name: 'Marketplace',
  //   url: '/nft-marketplace',
  // },
  {
    name: 'My Awards',
    url: '/account/profile',
    isAuthenticate: true,
    submenu: [
      {
        name: 'Profile',
        url: '/account/settings',
      },
      // {
      //   name: 'Public page',
      //   url: '/account/profile',
      // },
      // {
      //   name: 'History',
      //   url: '/account/history/purchase',
      // },
    ],
  },
];

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { loggingIn } = useSelector((state: StoreState) => state.authenticate);
  const dispatch = useDispatch();
  const top = useScrollTracking();
  const { isScrollingDown } = useScrollDirection();

  const handleLogout = useCallback(() => {
    dispatch(doLogout.request(''));
  }, [dispatch]);
  const closeMobileMenu = useCallback(() => {
    if (window?.innerWidth < 992) {
      setToggleMenu(false);
    }
  }, []);

  return (
    <div
      className={cx(classes.wrapHeader, 'd-flex', {
        [classes.toggleMenuMobile]: toggleMenu,
        [classes.isHidden]: top > 80 && isScrollingDown,
      })}
    >
      <div
        className={cx(
          classes.header,
          classes.container,
          'd-flex align-items-center justify-content-between',
        )}
      >
        <Link to="/" className="d-block" onClick={closeMobileMenu}>
          <img src={pbaLogo} alt="Logo" className={classes.logo} />
        </Link>
        <div className="d-flex">
          <ul className={cx(classes.wrapMenus)}>
            {Menus.map((menu: any) => {
              if (menu.isAuthenticate && !loggingIn) {
                return null;
              }
              return (
                <li key={menu.name}>
                  <Link
                    to={menu.url}
                    className={classes.link}
                    onClick={closeMobileMenu}
                  >
                    {menu.name}
                  </Link>
                  {menu.submenu && (
                    <ul className={classes.subMenu}>
                      {menu.submenu.map((subItem: any) => {
                        return (
                          <li key={subItem.name}>
                            <Link
                              to={subItem.url}
                              className={classes.subMenuItem}
                              onClick={closeMobileMenu}
                            >
                              {subItem.name}
                            </Link>
                          </li>
                        );
                      })}
                      {menu.name === 'My Awards' && (
                        <li>
                          <Button
                            buttonType="clear"
                            className={classes.subMenuItem}
                            onClick={handleLogout}
                          >
                            Logout
                          </Button>
                        </li>
                      )}
                    </ul>
                  )}
                </li>
              );
            })}
            {loggingIn ? (
              <li className={classes.wrapCart}>
                <Cart />
              </li>
            ) : (
              <>
                <li>
                  <Link
                    to="/auth/login"
                    className={classes.link}
                    onClick={closeMobileMenu}
                  >
                    Login
                  </Link>
                </li>
              </>
            )}

            {/* <li>
            <div className={cx(classes.rewardActive, 'd-flex')}>
              <img src={images.pages.home.rewardActive} alt="rewardActive" />
              <div className="font-weight-bold">
                DAILY
                <br />
                REWARDS
              </div>
            </div>
          </li>
          <li>
            <div className={cx('ml-3 mr-5', classes.language)}>vi</div>
          </li> */}
          </ul>
          <ImageButton
            clear={true}
            className={classes.menuIcon}
            onClick={() => setToggleMenu((prev) => !prev)}
          >
            <MenuIcon active={toggleMenu} />
          </ImageButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
