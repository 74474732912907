import { useEffect, useRef, useState } from 'react';

const THRESHOLD = 0;

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState('down');
  const [isScrollingDown, setIsScrollingDown] = useState(true);
  const [isScrollingUp, setIsScrollingUp] = useState(false);

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    prevScrollY.current = document.body.scrollTop;

    const updateScrollDirection = () => {
      const scrollY = document.body.scrollTop;

      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        let newScrollDirection;
        if (scrollY > prevScrollY.current) {
          newScrollDirection = 'down';
          setIsScrollingDown(true);
          setIsScrollingUp(false);
        } else {
          newScrollDirection = 'up';
          setIsScrollingDown(false);
          setIsScrollingUp(true);
        }

        setScrollDirection(newScrollDirection);

        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    window.addEventListener('scroll', onScroll, true);

    return () => window.removeEventListener('scroll', onScroll, true);
  }, [scrollDirection]);

  return { isScrollingDown, isScrollingUp, scrollDirection };
};

export { useScrollDirection };
