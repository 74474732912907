import React, { FC, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthLayout from 'layout/AuthencationLayout';
import { store } from './store';
import { history } from './helpers/history';
import AppRoutes from './layout/MainLayout';
import NotFound from './container/404';
import 'swiper/css/swiper.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/styles.scss';

const App: FC = () => {
  // eslint-disable-next-line no-console
  console.debug('App version 0.1.32');
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="wrap-global-loading">
            <div className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </div>
        }
      >
        <ToastContainer />
        <Router history={history}>
          <Switch>
            {/* <Route
              path={'/'}
              render={() => <Redirect to="/auth/login" />}
              exact={true}
            /> */}
            <Route component={AuthLayout} path="/auth" exact={false} />
            <Route component={AppRoutes} path="/" exact={false} />
            <Route component={NotFound} path="*" exact={true} />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  );
};

export default App;
