import React, { ImgHTMLAttributes, useCallback, FC } from 'react';
import images from 'assets/img';
import Skeleton from 'react-loading-skeleton';

interface Props extends ImgHTMLAttributes<any> {
  imgLoading?: boolean;
  autoPlay?: boolean;
  mutedVideo?: boolean;
}

const ImgLoadable: FC<Props> = ({
  src,
  imgLoading,
  mutedVideo,
  autoPlay,
  ...props
}) => {
  const handleErrorImg = useCallback((e: any) => {
    e.target.onerror = null;
    e.target.src = images.common.notFoundNft;
  }, []);
  if (imgLoading || !src) {
    return <Skeleton width="100%" height="100%" className="h-100" />;
  }
  if (src?.includes('.mp4')) {
    return (
      <video
        loop={true}
        autoPlay={autoPlay ?? true}
        muted={mutedVideo ?? true}
        style={{ objectFit: 'cover' }}
        width="100%"
        preload="auto"
        className={props?.className}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
  return <img src={src} onError={handleErrorImg} alt="demoNft" {...props} />;
};

export default ImgLoadable;
