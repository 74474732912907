import { ListNftsTrending } from 'models/nft';
import {
  ContactForm,
  DetailItemBanner,
  FooterPromoInfoRes,
  IPbaCheck,
  PromoInfoRes,
} from 'models/common/common';
import unauthorizedRequest from './request/unauthorizedRequest';
import authorizedRequest from './request/authorizedRequest';

export function getListTrendingNfts() {
  return unauthorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    'api/nft/trending-list',
  );
}

export function checkPbaAdmin() {
  return authorizedRequest.get<IPbaCheck, IPbaCheck>('api/pba/admin-check');
}

export function getCountDownNextDrop() {
  return unauthorizedRequest.get<any, any>('api/pba/get-drop-countdown');
}

export function getPromoInfo() {
  return unauthorizedRequest.get<PromoInfoRes, PromoInfoRes>(
    'api/pba/get-promo-home',
  );
}

export function getHosttestPack() {
  return unauthorizedRequest.get<ListNftsTrending, ListNftsTrending>(
    'api/nft/hottest-packs-list',
  );
}

export function getFooterPromoInfo() {
  return unauthorizedRequest.get<FooterPromoInfoRes, FooterPromoInfoRes>(
    'api/pba/get-promo-footer',
  );
}

export function getHomeBanner() {
  return unauthorizedRequest.get<any, any>('api/pba/get-banner-home');
}

export function getDetailItemBanner() {
  return unauthorizedRequest.get<DetailItemBanner, DetailItemBanner>(
    'api/pba/get-banner-item-page',
  );
}

export function getFAQ() {
  return unauthorizedRequest.get<any, any>('api/faq/list');
}

export function contactUs(payload: ContactForm) {
  return unauthorizedRequest.post<any, any>('api/contact', payload);
}

export function trackSocialClick(payload: any) {
  return authorizedRequest.post<any, any>(
    'api/leaguepals-award/track-social',
    payload,
  );
}
