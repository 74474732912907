import { createAsyncAction } from 'typesafe-actions';
import { LoginRequestModel, UserInfoResponse } from 'models/authenticate';

export const doLogin = createAsyncAction(
  'DO_LOGIN_REQUESTED',
  'DO_LOGIN_SUCCESS',
  'DO_LOGIN_FAILURE',
)<LoginRequestModel, UserInfoResponse, string>();

export const initialNewSection = createAsyncAction(
  'INITIAL_NEW_SECTION_REQUESTED',
  'INITIAL_NEW_SECTION_SUCCESS',
  'INITIAL_NEW_SECTION_FAILURE',
)<string, string, string>();

export const doLogout = createAsyncAction(
  'LOGOUT_REQUESTED',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',
)<string, string, string>();
